<template>
  <v-dialog v-model="dialogState" height="100%" persistent scrollable max-width="500px">
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <!--        <v-card-title class="text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">-->
        <v-card-title class="text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between">

          <div>Edit Profile</div>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3 px-5 pt-2">
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field dense v-model="form.first_name" label="First Name" outlined
                            :rules="[getRules.required]"></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0">
              <v-text-field
                  dense
                  v-model="form.last_name"
                  label="Last Name"
                  outlined
                  :rules="[getRules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="py-1">
              <v-text-field dense v-model="form.phone" label="Phone" outlined></v-text-field>
            </v-col>

            <v-col cols="6" class="py-1">
              <v-text-field dense v-model="form.job_title" label="Job Title" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="px-5 py-3 elevation-4">
          <v-btn block color="primary" :loading="loading" @click="validate">
            <span>
              <v-icon class="mr-2">mdi-pencil</v-icon>
            Update
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import {mapGetters, mapMutations, mapActions} from 'vuex';

export default {
  name: "EditProfile",
  props: ['formType', 'dialogState'],
  data: () => ({
    validForm: true,
    loading: false,
    form: new Form(),
    successMsg:'',

  }),
  mounted() {
    this.initForm();
  },
  computed: {
    ...mapGetters(['getRules', 'getNewUser', 'getUser', 'getAuthUser', 'getUserErrors'])
  },
  methods: {
    async initForm(state = 'add') {
      this.form = new Form(this.getUser);
    },

    reset() {

    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submit();
      }
    },
    async submit() {
      this.form.organization_id = this.getAuthUser.organization_id;
      let user = Object.assign({}, this.form);
      this.loading = true;

      this.setUser(user);

      this.successMsg = 'Account Updated Successfully';
      await this.updateUser();
      this.updateAuth(user);

      this.loading = false;
      if (_.isEmpty(this.getUserErrors)){ this.showMessage(); this.closeForm(); }
      else{
        this.successMsg = 'Error Occur';
        this.showMessage('error');

      }
    },
    showMessage(type='success'){
      this.$emit('message',this.successMsg,type);
    },
    closeForm() {
      this.resetUserErrors();
      this.resetNewUser();
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit('close-form', true);
      this.form = new Form(this.getNewUser)

    },

    ...mapMutations(['setUser', 'setNewUser','resetNewUser','resetUserErrors','updateAuth']),
    ...mapActions(['addUser', 'updateUser'])
  }
}
</script>

<style scoped>

</style>